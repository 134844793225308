<template>
  <div class="display">
    <template v-if="verify">
      <div class="loading" v-if="loading">
        <van-loading type="spinner" />
      </div>
      <div class="box" v-else>
        <div class="header">
          <div class="avatar">
            <img :src="carddata.avatar" alt="" />
          </div>
          <div class="nickname">
            <h1>{{ carddata.nickname }}</h1>
          </div>
        </div>
        <div class="table">
          <van-cell-group inset>
            <van-field label="手机" :value="carddata.phone" readonly />
            <van-field label="微信" :value="carddata.wechat" readonly />
            <van-field label="QQ" :value="carddata.qq" readonly />
            <van-field label="邮箱" :value="carddata.email" readonly />
          </van-cell-group>
        </div>
        <div class="footer">
          <p>
            欢迎访问{{ carddata.nickname }}的个人卡片 浏览量{{ carddata.view }}
          </p>
          <p>COPYRIGHT © 2021 KAMINO CARD.</p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="notfound">
        <p>404找不到该页面</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carddata: {},
      loading: true,
      verify: true,
    };
  },
  created() {
    this.$axios
      .post("/data", {
        key: this.$route.params.key,
      })
      .then((response) => {
        this.carddata = response.data;
        document.title = this.carddata.nickname;
      })
      .catch((response) => {
        this.verify = false;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
.header {
  margin-top: 30px;
}
.avatar {
  width: 160px;
  height: 160px;
  margin: 0 auto;
}

.avatar img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

.nickname {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.table {
  margin-top: 30px;
}


.footer {
  margin-top: 20px;
}

.footer p {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.notfound {
  margin-top: 40%;
}

.notfound p {
  font-size: 24px;
  text-align: center;
}
</style>